import dayjs from 'dayjs'
import { useModel } from '@/plugin-model/useModel'
import { useCallback, useEffect, useState } from 'react'
import rawUseWebSocket, { ReadyState } from 'react-use-websocket'
import { forEach, merge, includes } from 'lodash-es'
import { randomAlphanums } from '@/utils/debouncedFunction'
import { bearerGet } from '@/utils/token'
import { wsURL } from '@/utils/url'
import { isBrowser, isJestTest } from '@/utils/utils'
import storage from '@/utils/storage'

let lastAuthMs: number | undefined
export function useWebSocket() {
  const ws =
    isBrowser() || isJestTest()
      ? rawUseWebSocket(wsURL(), {
          queryParams: { clientID: storage.clientID },
          share: true,
          shouldReconnect: () => true,
          reconnectAttempts: Number.MAX_VALUE,
          reconnectInterval: 3000,
        })
      : undefined
  const rawWebsocket: any = ws?.getWebSocket()
  if (rawWebsocket && ws?.lastJsonMessage && ws?.lastJsonMessage?.method === 'authed') {
    rawWebsocket.authed = true
  }
  const token = bearerGet()

  useEffect(() => {
    const currAuthMs = new Date().getTime()
    if (rawWebsocket && !rawWebsocket.authed && token && ws?.readyState === ReadyState.OPEN && (!lastAuthMs || currAuthMs - lastAuthMs > 1000)) {
      lastAuthMs = currAuthMs
      ws?.sendJsonMessage({
        method: 'auth',
        token,
      })
    }
  }, [rawWebsocket, token, ws?.readyState]) // eslint-disable-line

  return ws
}

const subscriptionCount: Record<string, number> = {}
export function useChannel(channel: string, canSubscribe: boolean | string | undefined, onMessage?: (obj: any) => void, data?: any, needIndex = true) {
  const ws = useWebSocket()
  const [lastIndex, setLastIndex] = useState(-1)

  const { requestAdd, wsSet } = useModel('ws')
  const rawWebsocket = ws?.getWebSocket()

  const sendJsonMessage = useCallback(
    (_request: Omit<ws.Request, 'id' | 'state' | 'start' | 'end' | 'channel'>) => {
      if (ws?.sendJsonMessage && channel) {
        const request: ws.Request = {
          channel,
          id: randomAlphanums(6),
          state: 'doing',
          start: dayjs().valueOf(),
          end: -1,
          ..._request,
        }
        ws?.sendJsonMessage(request)
        requestAdd(request)
      }
    },
    [channel, requestAdd, ws]
  )

  useEffect(() => {
    if (ws?.readyState === ReadyState.OPEN && canSubscribe) {
      subscriptionCount[channel] = (subscriptionCount[channel] ?? 0) + 1
      setLastIndex(-1)
      sendJsonMessage({
        method: 'subscribe',
        data,
      })
    }
    return () => {
      if (ws?.readyState === ReadyState.OPEN && canSubscribe) {
        subscriptionCount[channel] = (subscriptionCount[channel] ?? 0) - 1
        if (subscriptionCount[channel] === 0) {
          delete subscriptionCount.channel
          sendJsonMessage({
            method: 'unsubscribe',
            data,
          })
        }
      }
    }
  }, [rawWebsocket, channel, ws?.readyState, canSubscribe]) // eslint-disable-line

  useEffect(() => {
    const message = ws?.lastJsonMessage
    if (!message) {
      return
    }

    if (message.channel === channel && !includes(['unsubscribed', 'subscribed'], message.method)) {
      const msgIndex = message.index ?? 0

      // 此标记为不重发
      if (msgIndex <= -10 || !needIndex) {
        onMessage?.(message)
        return
      }

      if (message.method === 'resend') {
        forEach(message.messages ?? [], (m: string) => {
          const parsedMessage = JSON.parse(m)
          setLastIndex(parsedMessage.index ?? 0)
          onMessage?.(parsedMessage)
        })
        return
      }

      if (msgIndex <= lastIndex) {
        // 已处理信息
        return
      }
      if (msgIndex - lastIndex !== 1) {
        sendJsonMessage({
          method: 'resend',
          data: { lastIndex },
        })
        return
      }

      setLastIndex(msgIndex)
      onMessage?.(message)
    }
    if (process.env.WS_DEVTOOL) {
      wsSet(`requests.${message.id}`, (value: Record<string, any>) => {
        merge(value, {
          state: 'done',
          end: dayjs().valueOf(),
        })
      })
    }
  }, [ws?.lastJsonMessage]) // eslint-disable-line

  return { ...ws, sendJsonMessage }
}
